import PageHeader from "../PageHeader";
import InfoKelulusan from "./info/InfoKelulusan";

export default function Information() {
  return (
    <>
      <PageHeader>Informasi Kelulusan</PageHeader>
      <InfoKelulusan />
    </>
  )
}