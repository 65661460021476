import React from "react";

import "../App.css";
import "./PageHeader.css";

function PageHeader({ children }) {
  return (
    <div className="page-header-container">
      <div className="container">
        <h2>{children}</h2>
      </div>
    </div>
  );
}

export default PageHeader;
