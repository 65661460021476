import React from "react";
import FadeIn from "react-fade-in";

import "./Images.css";
// images
import maknaLogo from "../../../assets/images/makna-logo.png";
import blueHexagon from "../../../assets/images/blue-hexagon.png";
import greenHexagon from "../../../assets/images/green-hexagon.png";
import redHexagon from "../../../assets/images/red-hexagon.png";

function Images() {
  return (
    <FadeIn delay={1000}>
      <div className="container">
        <div className="about-image-title">
          <h5>Makna Logo dan Warna</h5>
        </div>
        <div className="about-image">
          <img
            style={{ marginBottom: "75px" }}
            src={maknaLogo}
            className="img-fluid"
            alt="Makna Logo SMK TI Bazma"
          />
          <p style={{ textAlign: "justify" }}>
            Warna SMK TI BAZMA diturunkan dari warna Pertamina sebagai ruh atas
            lahirnya sekolah, hal ini mewakili semangat berbagi para Perwira
            Pertamina. Makna dari warna logo adalah:
          </p>
          <div id="hexagon-container">
            <div className="row">
              <div className="hexagon-row">
                <img
                  src={blueHexagon}
                  className="hexagon img-fluid"
                  alt="hexagon biru"
                />
                <p>
                  Warna Biru mencerminkan tekad SMK TI BAZMA dalam mencetak
                  insan yang progresif, inovatif dan kreatif di bidang Teknologi
                  Digital. Warna ini juga bermakna kekuatan serta kewibawaan
                  para insan agar mampu menjalankan khasanah keilmuan dengan
                  penuh tanggung jawab.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="hexagon-row">
                <img
                  src={greenHexagon}
                  className="hexagon img-fluid"
                  alt="hexagon hijau"
                />
                <p>
                  Warna Hijau mencerminkan identitas alam dan ke-Islam-an,
                  sebagai entitas yang peduli terhadap lingkungan dan berpegang
                  teguh kepada nilai-nilai Islami dalam kehidupan.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="hexagon-row">
                <img
                  src={redHexagon}
                  className="hexagon img-fluid"
                  alt="hexagon merah"
                />
                <p>
                  Warna Merah mencerminkan keberanian, pantang menyerah serta
                  mampu memancarkan energi positif bagi sekitar. Warna ini
                  mencitrakan SMK TI BAZMA sebagai refleksi semangat Para
                  Perwira dalam mengharumkan citra Bangsa Indonesia ke manca
                  negara.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FadeIn>
  );
}

export default Images;
