import React from "react";
import FadeIn from "react-fade-in";

import "./Images.css";
// images
import elnusaLogo from "../../../assets/images/elnusa-logo.png";
import pertaminaLogo from "../../../assets/images/pertamina-logo.png";
import tuguMandiriLogo from "../../../assets/images/tugu-mandiri-logo.png";
import universitasPertaminaLogo from "../../../assets/images/universitas-pertamina-logo.png";

function Images() {
  return (
    <FadeIn>
      <div className="container">
        <div className="images-container">
          <div className="images-title">
            <h5>Daftar Mitra</h5>
          </div>
          <div className="images-content row">
            <p>
              SMK TI Bazma adalah wujud cita-cita bersama dalam rangka
              memberikan ruang bagi peserta didik untuk dapat berkarya dan
              menjadi generasi produktif. Dengan bekal kecerdasan kognitif,
              emosional, dan spritual yang baik, siswa-siswa ini diharapkan
              dapat menjadi generasi unggul dan berdaya saing global.
            </p>
            <p>
              Oleh karena itu, SMK TI Bazma menjalin kerja sama dengan berbagai
              mitra Dunia Usaha dan Dunia Industri (DU/DI) sebagai upaya
              menyelaraskan kebutuhan industri dengan persiapan SDM yang
              kompeten. Bentuk kerja sama tersebut, antara lain:
            </p>
            <p>
              <ol>
                <li>
                  Memberikan kesempatan kepada siswa untuk melakukan Praktek
                  Kerja Industri (Prakerin) sebagai bekal persiapan menghadapi
                  dunia kerja,
                </li>
                <li>
                  Membantu dalam menyusun kurikulum yang selaras dengan sektor
                  industri masa kini untuk meningkatkan kemampuan SDM agar dapat
                  bersaing di dunia industri.
                </li>
              </ol>
            </p>
          </div>
          <div className="images row">
            <img
              src={pertaminaLogo}
              className="col-md partner-logo"
              id="pertamina-logo"
              alt="Pertamina"
            />
            <img
              src={universitasPertaminaLogo}
              className="col-md partner-logo"
              id="universitas-pertamina-logo"
              alt="Universitas Pertamina"
            />
            <img
              src={elnusaLogo}
              className="col-md partner-logo"
              id="elnusa-logo"
              alt="elnusa"
            />

            <img
              src={tuguMandiriLogo}
              className="col-md partner-logo"
              id="tugu-mandiri-logo"
              alt="Tugu Mandiri"
            />
          </div>
        </div>
      </div>
    </FadeIn>
  );
}

export default Images;
