import React from "react";
import FadeIn from "react-fade-in";

import "../../App.css";
import PageHeader from "../PageHeader";

// import BSILogo from "../../assets/images/BSI.svg";

export default function Donation() {
  return (
    <>
      <PageHeader>Donasi</PageHeader>
      <div className="container">
        <div className="donation-container row">
          <FadeIn className="col-xs" delay={100}>
            <div className="donation-content">
              <p>
                Yayasan Baituzzakah Pertamina (Bazma) adalah Lembaga Nirlaba
                yang berkhidmat mengangkat harkat sosial kemanusiaan dengan
                mendayagunakan dana zakat, infak/sedekah dan wakaf (Ziswaf) yang
                bersumber dari masyarakat. Dana yang terhimpun disalurkan dalam
                beragam bentuk program sosial, pendidikan, kesehatan, pelayanan
                dhuafa, penanganan bencana dan pemberdayaan ekonomi masyarakat
                di seluruh Indonesia.
              </p>
              <p>
                Salah satu program yang sedang digulirkan adalah wakaf
                Pembangunan SMK TI Bazma, sebuah model pengelolaan pendidikan
                yang diharapkan bisa mencetak kader-kader berkarakter unggul dan
                berdaya saing bersaing di era global.
              </p>
              <p>
                Salurkan Wakaf terbaik anda melalui{" "}
                <b>Bank Syariah Indonesia (BSI)</b> :
              </p>
              <div className="lst" style={{ marginBottom: "24px" }}>
                <span style={{ fontWeight: 600, fontSize: "20px" }}>
                  711.88.444.88
                </span>
              </div>
              <p>A.N Yayasan Baituzzakah Pertamina</p>
              <p>Konfirmasi donasi: 0812 9077 1055</p>
            </div>
          </FadeIn>
        </div>
      </div>
    </>
  );
}
