import { useEffect, useRef } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";

export default function InfoKelulusan() {
  const refIframe = useRef(null);

  useEffect(() => {
    refIframe.current.setAttribute(
      'src',
      './file/SuratKeputusanKepalaSekolah2024.pdf',
    );
  }, []);
  return (
    <FadeIn>
      <div className="container">
        <div className="images-container">
          <div className="images-title">
            <h5>Informasi Kelulusan PPDB</h5>
          </div>
          <div className="images-content row">
            <p>
              🌟𝗣𝗲𝗻𝗴𝘂𝗺𝘂𝗺𝗮𝗻 𝗣𝗣𝗗𝗕 𝟮𝟬𝟮𝟰 🌟<br /><br />
              Kami telah mengumumkan hasil akhir seleksi PPDB kepada peserta seleksi PPDB SMK TI BAZMA.<br /><br />
              SK PENETAPAN PESERTA DIDIK SMK TI BAZMA dapat diunduh pada file terlampir.<br /><br />
              Kami ucapkan selamat kepada 26 Peserta Seleksi yang lolos dan dinyatakan diterima sebagai Peserta Didik SMK TI BAZMA Angkatan #4 ✨<br /><br />
              Untuk Peserta Seleksi yang tidak lolos, hal tersebut bukan berarti dirimu tidak cukup baik dalam mengikuti seluruh rangkaian seleksi, tetapi bisa jadi SMK TI BAZMA bukan pilihan yang terbaik untukmu saat ini. Bismillah, kami mendoakan agar disertai kemudahan dalam ikhtiarnya mendapatkan sekolah terbaik!😄🙏<br /><br />
              Terima kasih<br />
              TIM SELEKSI PPDB<br />
              SMK TI BAZMA
            </p>
            <br />
            <iframe
              title="Iframe Doc"
              ref={refIframe}
              width="300%"

              height="1000px"
              frameBorder="0"
            />
            <br />
            <a href="https://drive.google.com/file/d/12LtQXx02UyFx2zcK1vmgo7mIPKJ3pC0u/view?usp=drive_link" target="_blank" className="btn btn-primary">Lihat Surat Keputusan</a>
          </div>
        </div>
      </div>
    </FadeIn>
  )
}
