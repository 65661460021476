import "../../App.css";
import HeroSection from "../HeroSection";
import Brief from "./home/Brief";

function Home() {
  return (
    <>
      <HeroSection />
      <Brief />
    </>
  );
}

export default Home;
