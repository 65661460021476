import React from 'react'
// import FadeIn from "react-fade-in";
import "../../../App.css";

export default function InfoCareer() {
  return (
    <div className="container">
      <div className="career-container row">
        {/* <FadeIn>
          <div className="center">
            <img
              src="https://res.cloudinary.com/dhyq9uts4/image/upload/v1685506935/00978d46-2a67-4119-a184-eeb5195089e3_mju1uf.jpg"
              className="poster"
              alt="Poster Lowongan"
            />
          </div>
        </FadeIn>
        <FadeIn className="col-xs" delay={100}>
          <h5>
            <b>
              Lowongan Pekerjaan
            </b>
          </h5>
          <p>
            Kami mencari individu yang berdedikasi untuk membantu dan membimbing Peserta Didik dalam mengembangkan potensi mereka di lingkungan asrama
          </p>
          <p>
            🤵sebagai :
            <br />
            𝗣𝗲𝗻𝘆𝗲𝗹𝗶𝗮 𝗔𝘀𝗿𝗮𝗺𝗮 𝗦𝗠𝗞 𝗧𝗜 𝗕𝗔𝗭𝗠𝗔
            <br />
            <br />
            📬Daftar melalui Web atau link : <a target="_blank" rel="noreferrer" href="https://s.id/PenyeliaAsrama">https://s.id/PenyeliaAsrama (Daftar Penyelia Asrama)</a>
            <br />
            Narahubung : 𝟬𝟴𝟮𝟮 𝟲𝟮𝟭𝟳 𝟬𝟬𝟭𝟴 (𝗔𝗵𝗺𝗮𝗱 𝗗𝗮𝗵𝗹𝗮𝗻)
            <br />
            📍Daftar Sebelum :
            𝟬𝟱 𝗝𝘂𝗻𝗶 𝟮𝟬𝟮𝟯
            <br />
            <br />
            Info lebih lanjut dapat menghubungi nomor di atas
            <br />
            Terima kasih
            <br /><br />
            #LowonganKerja #PenyeliaAsrama #SMKTIBazma #Pendidikan #smkbisa #smkhebat
          </p>
        </FadeIn> */}
      </div>
    </div>
  )
}
