import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.css";

// components
import ScrollToTop from "./ScrollToTop";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// pages
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Partner from "./components/pages/Partner";
import Career from "./components/pages/Career";
import Donation from "./components/pages/Donation";
import Contact from "./components/pages/Contact";
import Information from "./components/pages/Information";
function App() {
  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/partner" component={Partner} />
          <Route path="/career" component={Career} />
          <Route path="/donation" component={Donation} />
          <Route path="/contact" component={Contact} />
          <Route path="/information" component={Information} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
