import React from "react";

import "../../App.css";
import PageHeader from "../PageHeader";
import AboutSchool from "./about/AboutSchool";
import VisiMisi from "./about/VisiMisi";
import SchoolFacility from "./about/SchoolFacility";
import DormFacility from "./about/DormFacility";
import Images from "./about/Images";
import Prodi from "./about/Prodi";

function About() {
  return (
    <>
      <PageHeader>Tentang SMK TI Bazma</PageHeader>
      <AboutSchool />
      <VisiMisi />
      <SchoolFacility />
      <DormFacility />
      <Images />
      <Prodi />
    </>
  );
}

export default About;
