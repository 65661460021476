import React from "react";
import FadeIn from "react-fade-in";

import "../App.css";
import "./HeroSection.css";

// components
import { Button } from "./Button";

function HeroSection() {
  return (
    <FadeIn>
      <div className="hero-container">
        <h1>SMK TI BAZMA</h1>
        <p>Merajut Asa, Menjejak & Menebar Manfaat</p>
        <div className="hero-btns">
          <Button
            className="btns"
            buttonStyle="btn--secondary"
            buttonSize="btn--large"
            path="/about"
          >
            INFO <i className="far fa-play-circle" />
          </Button>
        </div>
      </div>
    </FadeIn>
  );
}

export default HeroSection;
