import React from "react";
import FadeIn from "react-fade-in";

import "./VisiMisi.css";

function VisiMisi() {
  return (
    <div className="container">
      <div className="visi-misi-container row">
        <FadeIn className="col-xs" delay={400}>
          <div className="visi-misi-title">
            <h5>Visi dan Misi</h5>
            <h2>Visi</h2>
          </div>
          <div className="visi-misi-content">
            <p className="visi-misi-content">
              Menjadi sekolah yang melahirkan generasi berkarakter unggul,
              berkepribadian Islami, mandiri, beprestasi dan menebar manfaat.
            </p>
          </div>
        </FadeIn>
        <FadeIn className="col-xs" delay={400}>
          <div className="visi-misi-title">
            <h2>Misi</h2>
          </div>
          <div className="visi-misi-content">
            <ol>
              <li>
                Menyelenggarkan Sekolah Menengah Kejuruan (SMK) yang
                berkualitas,
              </li>
              <li>Melahirkan lulusan yang berkarakter unggul siap kerja,</li>
              <li>Mewujudkan generasi SDM yang berdaya saing global.</li>
            </ol>
          </div>
        </FadeIn>
      </div>
    </div>
  );
}

export default VisiMisi;
