import React from "react";
// import FadeIn from "react-fade-in";

import "../../App.css";
import PageHeader from "../PageHeader";
import InfoCareer from "./career/InfoCareer";
export default function Career() {
  return (
    <>
      <PageHeader>Karir</PageHeader>
      <InfoCareer />
    </>
  );
}
