import React from "react";

import "../../App.css";
import PageHeader from "../PageHeader";

export default function Contact() {
  return (
    <>
      <PageHeader>Kontak</PageHeader>
      <div className="container">
        <div className="contact-container row">
          <iframe
            title="map-smktibazma"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15854.329435408128!2d106.6890039!3d-6.5742745!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa31558d4689b78c5!2sIslamic%20Boarding%20School%20SMK%20TI%20BAZMA!5e0!3m2!1sen!2sid!4v1620237598612!5m2!1sen!2sid"
            width="600"
            height="450"
            style={{
              border: "0",
            }}
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </>
  );
}
