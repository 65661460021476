import React from "react";
import FadeIn from "react-fade-in";

import "./DormFacility.css";

function DormFacility() {
  return (
    <div className="container">
      <div className="dorm-facility-container row">
        <FadeIn className="col-xs" delay={800}>
          <div className="dorm-facility-title">
            <h5>Fasilitas Asrama</h5>
          </div>
          <div className="dorm-facility-content">
            <p>
              SMK TI Bazma merupakan sekolah berbasis asrama dengan fasilitas
              yang mumpuni demi mendukung proses belajar yang nyaman dan
              kondusif. Fasilitas di asrama terdiri dari fasilitas umum dan
              kamar tidur.
            </p>
            <p>
              Fasilitas umum:
              <ul>
                <li>WiFi,</li>
                <li>Kamar mandi,</li>
                <li>Tempat cuci bersama,</li>
                <li>Area menjemur pakaian,</li>
                <li>Ruang diskusi dan belajar mandiri,</li>
                <li>Dapur bersama.</li>
              </ul>
            </p>
            <p>
              Fasilitas kamar tidur:
              <ul>
                <li>Kasur busa,</li>
                <li>Tempat tidur,</li>
                <li>Lemari pakaian,</li>
                <li>Meja dan kursi,</li>
              </ul>
            </p>
          </div>
        </FadeIn>
      </div>
      <div className="dorm-facility-container row">
        <FadeIn className="col-xs" delay={800}>
          <div className="dorm-facility-title">
            <h5>Program Asrama</h5>
          </div>
          <div className="dorm-facility-content">
            <ol>
              <li>Program bimbingan kepribadian dan kepemimpinan,</li>
              <li>Program pemantapan tahsin dan tahfidz Al-Qur’an,</li>
              <li>
                Program penumbuhan sikap kemandirian, toleransi dan kerja sama,
              </li>
              <li>
                Program pengembangan minat dan bakat dalam berbagai bidang
                (olahraga, seni, budaya dan keterampilan lainnya).
              </li>
            </ol>
          </div>
        </FadeIn>
      </div>
    </div>
  );
}

export default DormFacility;
