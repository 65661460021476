import React from "react";
import FadeIn from "react-fade-in";

import "./Brief.css";

// components
import { Button } from "../../Button";

function Brief() {
  return (
    <div className="container">
      <div className="brief-container row">
        <div className="brief-left col-lg">
          <FadeIn delay={500}>
            <h1>
              Islamic Boarding School
              <br />
              SMK TI BAZMA
            </h1>
          </FadeIn>
        </div>
        <div className="brief-right col-lg">
          <FadeIn delay={500}>
            <p>
              Sekolah Menengah Kejuruan Teknologi Informasi Bazma (SMK TI BAZMA)
              merupakan sekolah unggulan berasrama yang diperuntukkan bagi
              anak-anak tidak mampu. Pembangunan sekolah, baik fasilitas maupun
              operasional didanai dari hasil pengelolaan wakaf dan sumber dana
              sosial kemanusiaan lainnya yang diamanahkan oleh masyarakat.
            </p>
            <p />
            <p>
              SMK TI BAZMA menyelenggarakan program pembelajaran yang ditempuh
              selama 4 tahun dengan siswa-siswa yang berasal dari berbagai
              daerah di seluruh Indonesia. SMK TI Bazma menyelenggarakan
              pendidikan dengan jurusan SIJA (Sistem Informatika, Jaringan &
              Aplikasi) dengan kombinasi kurikulum berbasis asrama.
            </p>
            <div className="brief-btn-section">
              <Button
                className="btns"
                buttonStyle="btn--primary"
                buttonSize="btn--medium"
                roundedCorner={true}
                path="/about"
              >
                selengkapnya
              </Button>
            </div>
          </FadeIn>
        </div>
      </div>
    </div>
  );
}

export default Brief;
