import React from "react";
import FadeIn from "react-fade-in";

import CardItem from "../../CardItem";

import "./SchoolFacility.css";
// images
import ruangKelasImg from "../../../assets/images/r_kelas.jpg";
import masjidImg from "../../../assets/images/masjid.jpg";
import asramaImg from "../../../assets/images/asrama.jpg";
import lapanganImag from "../../../assets/images/lapangan.jpg";
import fasilitas from "../../../assets/images/fasilitas.png";

function SchoolFacility() {
  return (
    <FadeIn delay={600}>
      <div className="container">
        <div className="school-facility-title">
          <h5>Fasilitas Sekolah</h5>
        </div>
        <div className="cards">
          <div className="cards__container">
            <div className="cards__wrapper">
              <ul className="cards__items">
                <CardItem
                  src={ruangKelasImg}
                  text="Ruang Kelas & Lab"
                  label="Fasilitas Sekolah"
                />
                <CardItem
                  src={masjidImg}
                  text="Masjid & Aula"
                  label="Fasilitas Sekolah"
                />
                <CardItem
                  src={asramaImg}
                  text="Gedung Asrama"
                  label="Fasilitas Sekolah"
                />
                <CardItem
                  src={lapanganImag}
                  text="Lapangan Olahraga"
                  label="Fasilitas Sekolah"
                />
              </ul>
            </div>
            <img
              src={fasilitas}
              className="img-fluid"
              alt="Fasilitas SMK TI Bazma"
            />
          </div>
        </div>
      </div>
    </FadeIn>
  );
}

export default SchoolFacility;
