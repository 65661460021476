import React from "react";

import "../../App.css";
import PageHeader from "../PageHeader";
import Images from "./partner/Images";

function Partner() {
  return (
    <>
      <PageHeader>Kemitraan / Hubungan Industri</PageHeader>
      <Images />
    </>
  );
}

export default Partner;
