import React from "react";

import "./Footer.css";

import logo from "../assets/images/secondary-logo.png";

function Footer() {
  return (
    <div className="footer-container">
      <div className="container footer-outer-box">
        <div className="footer-links row">
          <div className="footer-link-wrapper col-md">
            <div className="footer-link-items">
              <div className="footer-logo">
                <img
                  src={logo}
                  className="logo-image"
                  alt="Logo SMK TI Bazma"
                />
              </div>
            </div>
          </div>
          <div className="footer-link-wrapper col-md">
            <div className="footer-link-items">
              <h5>Lokasi</h5>
              <p>
                Jl. Raya Cikampak Cicadas RT. 001/RW 001. Kecamatan Ciampea,
                Kabupaten Bogor. Jawa Barat 16620
              </p>
            </div>
          </div>
          <div className="footer-link-wrapper col-md">
            <div className="footer-link-items">
              <h5>Email</h5>
              <p>
                <a
                  href="mailto:info@smktibazma.sch.id"
                  target="_blank"
                  rel="noreferrer"
                >
                  info@smktibazma.sch.id
                </a>
              </p>
            </div>
          </div>
          <div className="footer-link-wrapper col-md">
            <div className="footer-link-items">
              <h5>Nomor Telepon</h5>
              <p>08 1111 4433 9</p>
            </div>
          </div>
        </div>
        <section className="social-media">
          <div className="social-media-wrap">
            <div className="social-icons">
              <a
                className="social-icon-link facebook"
                href="https://facebook.com/#"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                className="social-icon-link instagram"
                href="https://instagram.com/smktibazma"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <i className="fab fa-instagram" />
              </a>
              <a
                className="social-icon-link youtube"
                href="https://youtube.com/#"
                target="_blank"
                rel="noreferrer"
                aria-label="Youtube"
              >
                <i className="fab fa-youtube" />
              </a>
              <a
                className="social-icon-link twitter"
                href="https://twitter.com/#"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <i className="fab fa-twitter" />
              </a>
            </div>
          </div>
        </section>
        <small className="website-rights">SMKTIBazma © 2021</small>
      </div>
    </div>
  );
}

export default Footer;
