import React from "react";
import FadeIn from "react-fade-in";

import "./AboutSchool.css";
import logo from "../../../assets/images/main-logo-2.png";

function AboutSchool() {
  return (
    <div className="container">
      <div className="about-school-container row">
        <div className="about-school-title">
          <h5>Profil Sekolah</h5>
        </div>
        <FadeIn className="col-lg" delay={200}>
          <div className="about-school-left ">
            <img src={logo} alt="Logo SMK TI Bazma" height="240px" />
          </div>
        </FadeIn>
        <FadeIn className="col-lg" delay={200}>
          <div className="about-school-right">
            <p>
              <strong>
                Sekolah Menengah Kejuruan Teknologi Informasi Bazma (SMK TI
                BAZMA)<span> </span>
              </strong>
              merupakan sekolah unggulan berasrama yang diperuntukkan bagi
              anak-anak tidak mampu. Pembangunan sekolah, baik fasilitas maupun
              operasional didanai dari hasil pengelolaan wakaf dan sumber dana
              sosial kemanusiaan lainnya yang diamanahkan oleh masyarakat.
            </p>
            <p />
            <p>
              SMK TI BAZMA menyelenggarakan program pembelajaran yang ditempuh
              selama 4 tahun dengan siswa-siswa yang berasal dari berbagai
              daerah di seluruh Indonesia. SMK TI Bazma menyelenggarakan
              pendidikan dengan jurusan SIJA (Sistem Informatika, Jaringan &
              Aplikasi) dengan kombinasi kurikulum berbasis asrama.
            </p>
          </div>
        </FadeIn>
      </div>
    </div>
  );
}

export default AboutSchool;
