import React from "react";
import FadeIn from "react-fade-in";

import "./Prodi.css";

function Prodi() {
  return (
    <div className="container">
      <div className="prodi-container row">
        <FadeIn className="col-xs" delay={1200}>
          <div className="prodi-title">
            <h5>Kompetensi Keahlian</h5>
          </div>
        </FadeIn>
        <FadeIn className="col-xs" delay={1200}>
          <div className="prodi-content">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="prodi-1">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    SIJA (Sistem Informatika, Jaringan dan Aplikasi)
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Pembelajaran Jurusan SIJA di SMK TI BAZMA berlangsung
                      selama 4 tahun, dengan model pembelajaran 3 tahun di
                      sekolah sedangkan 1 tahun pembelajaran di industri dalam
                      bentuk Praktek Kerja Industri (PRAKERIN). Dengan program
                      studi 4 tahun ini, lulusan SIJA dapat setara dengan D1
                      atau lebih unggul satu level dari program keahlian 3 tahun
                      lainnya.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="prodi-2">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Apa saja yang dipelajari ?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>
                      Sistem komputer, jaringan komputer, pemrograman dasar,
                      dasar desain grafis , sistem IoT, komputasi awan, sistem
                      keamanan jaringan, materi sinkronisasi dengan industri,
                      serta produk kreatif dan kewirausahaan.
                    </p>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="prodi-3">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Peluang Kerja Lulusan SIJA
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <ol>
                      <li>Developer aplikasi (Programmer),</li>
                      <li>
                        IT support perangkat lunak, perangkat keras dan
                        jaringan,
                      </li>
                      <li>Konsultan IT (Kursus dan tutorial),</li>
                      <li>Wirausahawan di bidang IT,</li>
                      <li>
                        Wirausahawan di bidang desain grafis dan percetakan,
                      </li>
                      <li>Membuat start-up.</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeIn>
      </div>
    </div>
  );
}

export default Prodi;
